import React from 'react';
import telegram from '../../images/integrations/telegram.svg';
import IntegrationBot from '../../components/integrations/indegrations';

const TelegramPage = () => (
  <IntegrationBot
    botLogo={telegram}
    botName={'Telegram'}
  />
);

export default TelegramPage;
